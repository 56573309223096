/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --whislist-swipe-to-delete-color: #fff;
    --whislist-qty-button-size: 20px;
}

.WishlistItem {
    &:not(:last-child) {
        @include mobile {
            border-bottom: 1px solid $grey;
        }
    }

    &-ActionWrapper {
        display: inline-flex;
        align-items: center;

        &Top {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;

            @include desktop {
                position: static;
                margin-bottom: 40px;
            }

            @include tablet {
                position: absolute;
                margin-bottom: 0;
            }

            .EditIcon {
                margin-right: 17px;
                vertical-align: top;
            }
        }

        &Bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 20px;
            gap: 10px;

            @include desktop {
                justify-content: flex-end;
                gap: 38px;
            }

            @include tablet {
                justify-content: space-between;
            }
        }
    }

    &-Edit {
        &.Popup {
            //.ProductPage-Wrapper {
            //    grid-template-columns: 100%;
            //}
            //
            //.ProductGallery-Additional {
            //    display: none !important;
            //}
            //
            .ProductActions-GiftOptions_short_description {
                z-index: 0;
                margin-top: 0;
                padding: 20px 16px;

                @include mobile {
                    padding: 5px 16px;
                }

                &_content {
                    @include mobile {
                        font-size: 10px;
                        line-height: 12px;
                    }
                }
            }
        }
    }

    &-CopyIcon,
    &-MoveIcon {
        cursor: pointer;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &-InformationWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &Options-List,
    &Option {
        font-weight: 400;
        font-size: 12px;
    }

    &Options-item {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;

        label {
            font-weight: 600;

            &:after {
                content: ":";
            }
        }
    }

    &:first-of-type {
        @include mobile {
            border-block-start: none;
            padding-block-start: 0;
        }
    }

    &:last-of-type {
        @include mobile {
            margin-block-end: 0;
        }
    }

    &-ProductCard {
        &.ProductCard {
            display: flex;
            flex-direction: column;
            padding-top: 24px;
            padding-bottom: 24px;
            margin: 0;

            @include desktop {
                margin-block-end: 0;
                padding-top: 30px;
                padding-bottom: 30px;

                &:last-child {
                    margin-block-end: 0;
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid $grey;
            }
        }

        .ProductCard-Name {
            white-space: inherit;
        }

                .ProductCard {
                    &-Price {
                        // display: grid;
        
                        @include mobile {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
        
                            span {
                                display: inline-block;
                            }
                        }
                    }
                }

        .ProductPrice_hasDiscount {
            @include mobile {
                display: flex;
                flex-direction: column;
            }

            del {
                @include mobile {
                    order: -1;
                    margin-inline: 0;
                }
            }
        }

        .ProductPrice {
            &-Price {
                @include mobile {
                    width: min-content;
                    text-align: end;
                }
            }

            &-PriceBadge {
                @include mobile {
                    margin-inline-end: 0;
                }
            }
        }

        .ProductCard-Figure {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    &-FigureWrapper {

        h4 {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @include mobile {
                white-space: normal;
                max-width: calc(100% - 24px);
            }
        }
    }

    &-ContentWrapper {
        @include mobile {
            width: 100%;
            min-height: 130px;
        }
    }

    &-Remove[aria-label='Remove'] {
        .CloseIcon {
            height: 20px;
            width: 22px;
        }
    }

    &-AddToCart {
        display: flex;

        &_isEditingActive {
            display: none;
        }

        &.Button {
            min-width: 189px;
            height: 32px;
            background: $white;
            color: $black;

            &:not([disabled]):hover {
                height: 32px;
                background: $black;
                color: $white;
            }

            @include desktop {
                width: 100%;
            }
        }
    }

    &-Quantity {
        &Text {
            margin-inline-end: 10px;
        }

        &Input {
            margin-block-start: 0;

            button {
                width: 36px;
                height: 36px;

                &:not([disabled]) {
                    cursor: pointer;
                }
            }

            input {
                min-width: 36px;
                font-size: 14px;
            }

            &.Field {
                width: 0;
                height: 0;
                overflow: hidden;
                opacity: 0;
                margin: 0;
                padding: 0;
            }
        }

        &Wrapper {
            display: flex;
            align-items: center;
        }
    }

    &-Price {
        display: inline-block;
    }

    &-Content {
        padding-block-start: 0;
        padding-block-end: 9px;
        padding-inline: 9px;
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0;
        gap: 20px;

        @include desktop {
            align-items: center;
            gap: 10px;
        }

        .ProductCard-PriceWrapper {
            min-height: 0;
            margin: 0;
        }

        .ProductPrice {
            min-height: 0;
            display: flex;
            flex-direction: column;

            @include desktop {
                right: 9.2%;
            }

            &-DiscountPercentage {
                white-space: nowrap;
            }
        }

        .WishlistItem-ActionWrapper {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &-ImageWrapper {
        margin-inline-end: 15px;
        width: 100px;
    }

    &-CommentField {
        input {
            width: 100%;
        }
    }

    &-RowWrapper {
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: column;

        @include desktop {
            align-items: flex-end;
        }
    }

    &-AttributeWrapper {
        margin-block-start: 5px;
        margin-block-end: 12px;
        margin-inline: 0;
    }

    &-SelectWrapper {
        display: flex;
        width: 100%;
    }

    &-Select {
        display: none;
        align-items: center;
        justify-content: center;

        &_isEditingActive {
            width: calc(50px - 14px);
            padding-inline-end: 14px;
            display: flex;
        }
    }

    &-Picture {
        @include mobile {
            height: 100%;
        }
    }

    &-SwipeToDeleteRightSide {
        height: 100%;
        width: 100%;
        font-weight: 600;
        color: var(--whislist-swipe-to-delete-color);
        background-color: var(--swipe-to-delete-bg-color);
    }

    &-RatingPlaceholder {
        height: 18px;
    }

    &-Brand {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.0008em;
        margin-bottom: 4px;
    }

    &-Name {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;

        @include desktop {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.2px;
        }
    }

    &-sku {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.0008em;
        margin-bottom: 8px;
    }

    &-NameAndOptions {
        @include mobile {
            width: 100%;
        }
    }

    &-Main {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include desktop {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 50px;
        }

        @include ultra-narrow-desktop {
            gap: 20px;
        }

        @include tablet {
            gap: 12px;
            flex-direction: column;
            align-items: flex-start;
        }

        > * {
            flex: 1;
        }
    }

    &-Left {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 60px calc(100% - 76px);

        @include desktop {
            grid-gap: 20px;
            grid-template-columns: 112px calc(100% - 132px);
        }
    }

    &-Right {
        position: static;

        @include desktop {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        @include tablet {
            width: 100%;
        }
    }

    &-CommentField {
        display: none;
    }

    .SwipeToDelete-RightSideContentWrapper {
        display: none;
    }

    &-LeftContent {
        @include mobile {
            padding-right: 76px;
        }

        @include tablet {
            padding-right: 76px;
        }
    }
}
