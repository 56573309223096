/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.WishlistSharedPage {
    &-Products {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: max-content;
        grid-column-gap: 24px;

        @include tablet {
            grid-template-columns: repeat(4, 1fr);
        }

        @include mobile {
            grid-column-gap: 28px;
            grid-template-columns: 1fr;
            border: 0;
        }

        @include desktop {
            grid-column: 2;
        }
    }

    &-ActionBar {
        display: flex;
        padding: 0;
        justify-content: flex-end;
        z-index: 1;

        @include desktop {
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 48px;
        }

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &-CreatorsInfo {
        margin: 24px 0;
        font-weight: normal;

        @include mobile {
            margin-block-start: 14px;
            margin-block-end: 28px;
            margin-inline: 0;
            font-size: 21px;
        }
    }
}
