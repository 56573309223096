/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.GuestWishlist {
    &-Heading {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        max-width: 482px;
        margin: 0 auto 8px;
        letter-spacing: -0.2px;

        @include desktop {
            text-align: center;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.003em;
        }
    }
}
