/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

.WishlistPopup {
  &-Product {
    .Popup-Heading {
      margin-bottom: 14px;
    }
  }

  .ExpandableContent {
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    margin-bottom: 16px;

    &-Button {
      padding-top: 14px;
      padding-bottom: 14px;
      cursor: pointer;
    }

    &-Heading {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    &-Content {
      max-height: 0;
      overflow: hidden;
      opacity: 0;

      &_isContentExpanded {
        opacity: 1;
        max-height: 100%;
      }
    }
  }

  &NewCategory {
    &-container {
      display: flex;
      margin-bottom: 12px;

      .Field {
        margin: 0;
        width: 100%;

        input {
          width: 100%;
          min-height: 48px;
        }
      }
    }
  }

  &-Items {
    .Item {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.0008em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      margin: 0;

      &:not(:last-child) {
        border-bottom: 1px solid $grey;
      }

      &:before {
        display: none;
      }

      Button {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        height: 32px;

        &:not([disabled]):hover {
          height: 32px;
        }
      }

      label {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 2px;
      }
    }
  }

  .Button {
    width: auto;
  }

  &-tabs {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include wide-desktop {
      flex-direction: row;
    }

    .Item {
      margin: 0;

      @include wide-desktop {
        flex: 1;
      }

      &:before {
        display: none;
      }

      &:first-child {
        .MyAccountMyWishlist-Button {
          background: $icegrey;
        }
      }
    }

    .MyAccountMyWishlist-Button {
      background: transparent;
      border: 1px solid $grey;
      color: $black;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.0008em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 66px 16px 20px;
      height: auto;
      margin: 0 !important;
      width: 100%;

      @include wide-desktop {
        padding: 28px 46px 28px 20px;
      }

      &:not([disabled]):hover {
        padding: 16px 66px 16px 20px;
        height: auto;

        @include wide-desktop {
          padding: 28px 46px 28px 20px;
        }
      }

      &:after {
        content: "";
        border-style: solid;
        border-width: 1px;
        border-color: transparent $black $black transparent;
        width: 6px;
        height: 6px;
        display: block;
        position: absolute;
        top: 30px;
        right: 30px;
        transform: rotate(-45deg);

        @include wide-desktop {
          top: 42px;
          right: 20px;
        }
      }

      label {
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
        letter-spacing: 0;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  &_remove {
    &_content {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.0008em;
      margin: 20px 0 24px;
    }

    &_action {
      display: flex;
      justify-content: space-between;
      grid-gap: 16px;
      gap: 16px;

      button {
        flex-basis: 50%;
      }
    }
  }
}